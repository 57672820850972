

import {Component, Vue, Watch} from "vue-property-decorator";
import GuestLayout from "@/views/layouts/GuestLayout.vue";
import AppLayout from "@/views/layouts/AppLayout.vue";
import {Socket} from "vue-socket.io-extended";
import {UYAP_PLATFORM_SOCKET} from "@/plugins/uyap-plugin/imza";
import app from "@/main";
import {ProgramPaths} from "@/enum/ProgramPaths";
import MenuBar from "@/components/page-components/MenuBar.vue";
import BildirimInfo from "@/components/infos/BildirimInfo.vue";
import UrunlerInfo from "@/components/infos/UrunlerInfo.vue";
import ImzaUyapConnectionInfo from "@/components/infos/ImzaUyapConnectionInfo.vue";
import UserInfoCard from "@/components/UserInfoCard.vue";
import AppFooter from "@/layout/AppFooter.vue";

@Component({
  components: {
    AppFooter,
    UserInfoCard,
    ImzaUyapConnectionInfo,
    UrunlerInfo,
    BildirimInfo,
    MenuBar,
  }
})

export default class App extends Vue {
  isAnaMenu = false;

  get userId() {
    let id = this.$store.state.user.id;
    return id;
  }

  get showDevWarn(){
    let isEicrapro= this.$route.path.includes(ProgramPaths.eicrapro);
    let isTestSite= location.hostname.split('.')[0]=='cloudtest'||location.hostname.split('.')[0]=='cloudbeta'||location.hostname.split('.')[0]=='localhost';
    return isEicrapro && isTestSite && this.$store.getters.isEicraproDevMode;
  }

  @Socket('player')
  onPlayer(player: any) {
    this.$store.commit("setPlayerDurum", Number(player))
  }

  @Socket('updateTasks')
  onUpdateTask() {
    this.$store.dispatch('updateTasks');
  }

  @Socket('uyapAvukatBilgileri')
  onAvukatBilgileriChange(avukatBilgileri: any) {
    this.$store.commit("setUyapAvukatBilgileri", avukatBilgileri)
  }

  @Socket('uyapconnection')
  async onUyapConnection(data: { connected: boolean, JSESSIONID: string }) {
    console.log('soket cevap uyap bağlı', data.connected ? 'EVET' : 'HAYIR')
    if (data) {
      this.$store.commit('setUyapConnection', data.connected);
      if (!(window.localStorage.hasOwnProperty('JSESSIONID') && window.localStorage.getItem('JSESSIONID') == data.JSESSIONID)) {
        localStorage.setItem('JSESSIONID', data.JSESSIONID);
        // TODO: port değişken yapılacak
        const cookieSetUrl = 'https://imza.eicrapro.com:6099' +  '/cookie?' + data.JSESSIONID + ';SameSite=None;Secure';
        await app.$uyap.SetCookie().run({url: cookieSetUrl});
      }
    }
  }

  @Watch('$route')
  onPathnameChange() {
    if (this.$route.path == ProgramPaths.ofispro ||
        this.$route.path == ProgramPaths.icrapro ||
        this.$route.path == ProgramPaths.eicrapro)
      this.isAnaMenu = true;
    else
      this.isAnaMenu = false;
  }

  @Watch('$socket.connected')
  onSocketConnectionChange() {
    if (!this.$socket.connected) {
      this.$socket?.client.disconnect();
      this.$store.commit('setUyapConnection', false);
      this.$store.commit('setImzaConnection', false);
      this.$store.dispatch('startImzaConnInterval');
    } else {
      this.$store.dispatch('stopImzaConnInterval');
    }
  }
}
