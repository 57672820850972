import Vue from 'vue';
import Vuex from 'vuex';
import {VueRouter} from "vue-router/types/router";
import {ProgramPaths} from "@/enum/ProgramPaths";
import {PersonelEntity} from "@/entity/PersonelEntity";
import gorevYoneticisi from "@/plugins/uyap-plugin/store/modules/GorevYoneticisi";
import app from "@/main";
import {AppState} from "@/types";
import {AvukatKisiselBilgileriSorgulamaCevap} from "@/plugins/uyap-plugin/uyap/AvukatKisiselBilgileriSorgulama";
import VueSocketIOExt from 'vue-socket.io-extended';
import {io} from 'socket.io-client';
// İmza websocket
const socket = io('https://imza.eicrapro.com:3000', {
    autoConnect: false,
    reconnection: false
});
Vue.use(VueSocketIOExt, socket);
Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        // @ts-ignore
        gorevYoneticisi
    },
    state: <AppState>{
        accessToken: localStorage.getItem('accessToken'),
        tenant_id: sessionStorage.getItem('tenantId'),
        user: <PersonelEntity>JSON.parse(sessionStorage.getItem('user') ?? "{}"),
        activeProcesses: JSON.parse(sessionStorage.getItem("activeProcesses") ?? "[]"),
        createTcKimlikErrors: [],
        eicraproDevMode: false,  // Mock data kullanmak için true yap
        imzaConnection: false,
        uyapConnection: false,
        uyapAvukatBilgileri: undefined,
        avukatlar: [],
        tenantList: [],
        personeller: [],
        imzaConnIntervalId: null,
        ssoUri: "https://giristest.zirve-bilgisayar.com/",
        programs: [
            {
                name: "Ofispro",
                programPath: ProgramPaths.ofispro,
                urls: [
                    "ofispro.com",
                    "test.ofispro.com",
                    "ofispro.lcl",
                    "localhost",
                ],
            },
            {
                name: "IcraproWeb",
                programPath: ProgramPaths.icrapro,
                urls: [
                    "icrapro.com",
                    "test.icrapro.com",
                    "cloudbeta.zirve-bilgisayar.com",
                    "cloudtest.zirve-bilgisayar.com",
                    "cloud.zirve-bilgisayar.com",
                    "icrapro.lcl",
                ]
            },
            {
                name: "e-İcrapro",
                programPath: ProgramPaths.eicrapro,
                urls: [
                    "eicrapro.com",
                    "test.eicrapro.com",
                    "eicrapro.lcl",
                ]
            }
        ]
    },
    actions: {
        connectToImza({commit}) {
            app.$imza.heartbeat().then(() => {
                commit('setImzaConnection', true);
                app.$toast.success('İmzacı bağlantısı tamamlandı.');
            }).catch((error: any) => {
                commit('setImzaConnection', false);
                app.$toast.error('İmzacı bağlantısı yapılamadı. (' + error + ')');
            });
        },
        startImzaConnInterval({state, commit, dispatch}) {
            if (!state.imzaConnection) {
                const intervalID = setInterval(() => {
                    dispatch('checkUyapSession');
                }, 30000);
                commit('setImzaConnIntervalId', intervalID);
            }
        },
        stopImzaConnInterval({state, commit}) {
            if (state.imzaConnIntervalId) {
                clearInterval(state.imzaConnIntervalId);
                commit('setImzaConnIntervalId', null);
            }
        },
        async checkUyapSession({state, commit, dispatch}) {
            try {
                if (state.eicraproDevMode) {
                    commit('setImzaConnection', true);
                    commit('setUyapConnection', true);
                } else {
                    await app.$imza.heartbeat();
                    await dispatch('updateTasks');
                    commit('setImzaConnection', true);
                }
            } catch (error) {
                app.$socket.client.disconnect();
                commit('setImzaConnection', false);
                commit('setUyapConnection', false);
                if (!state.imzaConnIntervalId)
                    dispatch('startImzaConnInterval');
            }
        },
        async getTenantList({state, commit}) {
            return new Promise(async (resolve, reject)=>{
                try {
                    if (state.tenantList.length == 0) {
                        let response = await Vue.prototype.$http.get("/api/v1/oauth/user-tenant-list");
                        commit('setTenantList', response.data);
                    }
                    return resolve(state.tenantList);
                } catch (e){
                    return reject(e);
                }
            });
        },
        async getSelectedTenantId({state, commit}) {
            return sessionStorage.getItem("tenantId");
        },
        async getUser({state, commit}) {
            if (!state.user.id) {
                let response = await Vue.prototype.$http.get("/api/v1/ben");
                commit('setUser', response.data);
            }
            return state.user;
        },
        async getAvukatlar({state, commit}) {
            if (state.avukatlar.length == 0) {
                let response = await app.$http.get("/api/v1/personel/filter?type=avukat");
                commit('setAvukatlar', response.data);
            }
            return state.avukatlar;
        },
        async getPersoneller({state, commit}) {
            if (state.personeller.length == 0) {
                let response = await app.$http.get("/api/v1/personel");
                commit('setPersoneller', response.data)
            }
            return state.personeller;
        },
        async loginWithSso({state, commit}) {
            Vue.prototype.$http.get('/api/v1/oauth/login').then((res: any) => {
                location.href = res.data;
            })
        },
        async tenantData({state,dispatch}){
            return new Promise(async (resolve, reject)=>{
                await dispatch('getTenantList');
               resolve(state.tenantList.find(item => item.tenant_id == state.tenant_id));
            });
        }
    },
    mutations: {
        setRouter(state: AppState, router: VueRouter) {
            state.router = router;
        },
        setUser(state: AppState, user: PersonelEntity) {
            state.user = user;
            sessionStorage.setItem("user", JSON.stringify(user));
        },
        setTenantId(state: AppState, tenantId: string) {
            sessionStorage.setItem("tenantId", tenantId);
            let tenant = state.tenantList.find(tenant=>tenant.tenant_id == tenantId);
            if(tenant)
                sessionStorage.setItem("selectedTenant", JSON.stringify(tenant));
            state.tenant_id = tenantId;
        },
        setAccessToken(state: AppState, accessToken: string) {
            state.accessToken = accessToken;
            localStorage.setItem("accessToken", accessToken);
        },
        setActiveProcess(state: AppState, activeProcess: any) {
            if (activeProcess.isRoot) {
                state.activeProcesses = [];
            }
            state.activeProcesses.push(activeProcess);
            sessionStorage.setItem("activeProcesses", JSON.stringify(state.activeProcesses))
        },
        setImzaConnection(state: AppState, connection: boolean) {
            state.imzaConnection = connection;
        },
        setUyapConnection(state: AppState, payload: boolean) {
            state.uyapConnection = payload;
        },
        setUyapAvukatBilgileri(state: AppState, avukat: AvukatKisiselBilgileriSorgulamaCevap) {
            state.uyapAvukatBilgileri = avukat;
        },
        setAvukatlar(state: AppState, data) {
            state.avukatlar = data;
        },
        setTenantList(state: AppState, data) {
            state.tenantList = data;
        },
        setPersoneller(state: AppState, data) {
            state.personeller = data;
        },
        setImzaConnIntervalId(state: AppState, intervalId) {
            state.imzaConnIntervalId = intervalId;
        }
    },
    getters: {
        router: (state: AppState) => state.router,
        accessToken: (state: AppState) => state.accessToken,
        isDev: (state: AppState) => !window.location.hostname.includes('.com'),
        isEicraPro: (state: AppState) => window.location.pathname.startsWith(ProgramPaths.eicrapro),
        isIcraPro: (state: AppState) => window.location.pathname.startsWith(ProgramPaths.icrapro),
        isOfisPro: (state: AppState) => window.location.pathname.startsWith(ProgramPaths.ofispro),
        isDavaPro: (state: AppState) => window.location.pathname.startsWith(ProgramPaths.davapro),
        isArabulucuPro: (state: AppState) => window.location.pathname.startsWith(ProgramPaths.arabulucupro),
        isImzaConnected: (state: AppState) => state.imzaConnection,
        isUyapConnected: (state: AppState) => state.uyapConnection,
        home: (state: AppState) => ProgramPaths.ofispro,
        isEicraproDevMode: (state: AppState) => state.eicraproDevMode,
        avukatBilgileri: (state: AppState) => state.uyapAvukatBilgileri,
        ssoConfig: (state: AppState) => state.ssoConfig,
        userData: (state: AppState) => state.user,
        licenses: (state: AppState) => state.user.licenses.filter(licence => licence.tenant_id == state.tenant_id),
        tenant: (state: AppState) => state.tenantList.find(item => item.tenant_id == state.tenant_id)
    }
});
