import Args from "./Args";
import {Mixins} from "../Mixins";
import TarafInterface from "./TarafInterface";
import TarafKisi from "./TarafKisi";
import TarafKurum from "./TarafKurum";

class FaizBilgileriSelectedFaizTuru {
    tktId: string = '';
    aciklama: string = '';
}

class SelectedAlacakKalemKodu {
    alacakKalemKodAciklama: string = '';
    alacakKalemKod: string = '';
}

class AlacakKalemFaizBilgileri {
    selectedFaizTuru: FaizBilgileriSelectedFaizTuru = new FaizBilgileriSelectedFaizTuru();
    faizOrani: string = '';
    faizOraniTL: string = '';
    faizOraniKurus: string = '';
    selectedFaizSureTipi: string = '';
    selectedFaizSureTipiAciklama: string = '';
    tktId: string = '';
    aciklama: string = '';
}

class AlacakKalemTemelBilgileri {
    alacakTutari: string = '';
    selectedParaBirimi: string = '';
    selectedParaBirimiAciklama: string = '';
    KDV: boolean = false;
    aciklama: string = '';
    selectedTarihTuru: string = ''
    detayliAciklama: string = ''
    alacakKalemKodAciklama: string = '';
    alacakKalemKod: string = '';
    selectedAlacakKalemKodu: SelectedAlacakKalemKodu = new SelectedAlacakKalemKodu();
}


export default class AlacakKalem {
    temelBilgileri: AlacakKalemTemelBilgileri = new AlacakKalemTemelBilgileri();
    faizBilgileri: AlacakKalemFaizBilgileri = new AlacakKalemFaizBilgileri();
    selectedTarafList: string = "";//,
    dovizMi: boolean = false;

    static fromJson(jsonData: any): AlacakKalem {
        let obj = Args.staticFromJson(jsonData,new AlacakKalem());
        obj.faizBilgileri = Args.staticFromJson(obj.faizBilgileri,new AlacakKalemFaizBilgileri);
        obj.temelBilgileri = Args.staticFromJson(obj.temelBilgileri,new AlacakKalemTemelBilgileri);
        return obj;
    }

    static parse(
        alacakKalemiDomNode: HTMLUnknownElement,
        args: Args,
        tarafList: Array<TarafKisi | TarafKurum>
    ) {
        let defaultHesapSecenegi = null; // Store dan gelmeli suan hep soracak sekilde calisiyor.
        let alacakKalemi = new AlacakKalem();
        let alacakKalemiTutar: any =
            Mixins.convertAlacakKalemTutar(
                Mixins.replaceCommaDots(alacakKalemiDomNode.getAttribute("alacakKalemTutar"))
            );
        /**
         alacakKalem tutari 0 ise UYAP da takip acilamiyor alacak kalemini takibe eklemiyoruz // 10/04/2019 // emre
         **/
        if (alacakKalemiTutar <= 0)
            return null;

        let alacakKalemKodTuru = Mixins.replaceCommaDots(alacakKalemiDomNode.getAttribute("alacakKalemKodTuru"));
        console.log("alacakKalemKodTuru", alacakKalemKodTuru);

        alacakKalemi.setTemelBilgiler(alacakKalemiDomNode);
        alacakKalemi.setFaizBilgileri(alacakKalemiDomNode);
        alacakKalemi.setSelectedTarafList(alacakKalemiDomNode, tarafList);

        if (alacakKalemi.temelBilgileri.alacakKalemKodAciklama == "")
            alacakKalemi.temelBilgileri.alacakKalemKodAciklama = alacakKalemi.temelBilgileri.aciklama;


        /*
        if (args.aciklama != null && alacakKalemKodTuru == "1") {
            alacakKalemi.temelBilgileri.aciklama = args.aciklama; // SABUN EUP 230
            alacakKalemi.temelBilgileri.detayliAciklama = alacakKalemi.temelBilgileri.selectedAlacakKalemKodu.alacakKalemKodAciklama;
        }
         */

        //Döviz takibiyse Hesap seçeneği soruluyormuş ...
        if (
            typeof alacakKalemi.temelBilgileri.selectedParaBirimi != "undefined" &&
            alacakKalemi.temelBilgileri.selectedParaBirimi.trim() != "PRBRMTL"
        ) {
            // console.log("TAKİP DÖVİZ", alacakKalemi.temelBilgileri.selectedParaBirimi);
            //TODO buraya bakmak lazim emre --
            alacakKalemi.temelBilgileri.selectedTarihTuru =
                Mixins.hesapSecenegi(alacakKalemi.temelBilgileri.selectedParaBirimi, defaultHesapSecenegi);
            alacakKalemi.dovizMi = true;
        }

        alacakKalemi.temelBilgileri.selectedAlacakKalemKodu.alacakKalemKodAciklama = alacakKalemi.temelBilgileri.alacakKalemKodAciklama;
        alacakKalemi.temelBilgileri.selectedAlacakKalemKodu.alacakKalemKod = alacakKalemi.temelBilgileri.alacakKalemKod;
        console.log("ALACAK KALEMM -- args.isIcrapro()", args.isIcrapro())
        console.log("ALACAK KALEMM -- args.isIlamli()", args.isIlamli())
        console.log("ALACAK alacakKalemi.temelBilgileri. -- ", alacakKalemi.temelBilgileri)

        if (args.isIcrapro() &&
            alacakKalemi.temelBilgileri.detayliAciklama != "" &&
            !args.isIlamli()
        ) { //xmldeki aciklama attr si
            alacakKalemi.temelBilgileri.selectedAlacakKalemKodu.alacakKalemKodAciklama = alacakKalemi.temelBilgileri.detayliAciklama; // ttt de duzgun gozukmesi icin son nihan hanimin istedigi is
            alacakKalemi.temelBilgileri.aciklama = alacakKalemi.temelBilgileri.detayliAciklama; // SABUN EUP 230
        }

        if (alacakKalemi.temelBilgileri.selectedAlacakKalemKodu.alacakKalemKod == "5")
            alacakKalemi.temelBilgileri.selectedAlacakKalemKodu.alacakKalemKodAciklama = 'Diğer Masraf Alacağı'; // EUP-553

        return alacakKalemi;
    }

    setTemelBilgiler(alacakKalemiNode: HTMLUnknownElement) {


        this.temelBilgileri.alacakTutari = Mixins.replaceCommaDots(alacakKalemiNode.getAttribute("alacakkalemtutar"));
        this.temelBilgileri.selectedParaBirimi = Mixins.nullUndefCleaner(alacakKalemiNode.getAttribute("tutartur"));
        this.temelBilgileri.selectedParaBirimiAciklama = Mixins.nullUndefCleaner(alacakKalemiNode.getAttribute("tutaradi"));
        this.temelBilgileri.detayliAciklama = Mixins.nullUndefCleaner(alacakKalemiNode.getAttribute("aciklama"));
        this.temelBilgileri.alacakKalemKodAciklama = Mixins.nullUndefCleaner(alacakKalemiNode.getAttribute("alacakkalemadi"));
        this.temelBilgileri.aciklama = Mixins.nullUndefCleaner(alacakKalemiNode.getAttribute("alacakkalemkodaciklama"));
        this.temelBilgileri.alacakKalemKod = Mixins.nullUndefCleaner(alacakKalemiNode.getAttribute("alacakkalemkod"));
    }

    setFaizBilgileri(alacakKalemiNode: HTMLUnknownElement) {
        let faizNode = alacakKalemiNode.querySelector('faiz');
        //console.log("faiz node",faizNode);
        if (typeof faizNode != "undefined" && faizNode) {//faiz varsa
            this.faizBilgileri.tktId = Mixins.nullUndefCleaner(faizNode.getAttribute("faiztipkod"));
            this.faizBilgileri.aciklama = Mixins.nullUndefCleaner(faizNode.getAttribute("faiztipkodaciklama"));
            this.faizBilgileri.faizOrani = Mixins.replaceCommaDots(faizNode.getAttribute("faizoran"));
            this.faizBilgileri.selectedFaizSureTipi = Mixins.nullUndefCleaner(faizNode.getAttribute("faizsuretip"));
            this.faizBilgileri.selectedFaizSureTipiAciklama = this.faizSureTipConverter(this.faizBilgileri.selectedFaizSureTipi);
            this.faizBilgileri.selectedFaizTuru.tktId = this.faizBilgileri.tktId ?? "";
            this.faizBilgileri.selectedFaizTuru.aciklama = this.faizBilgileri.aciklama;
            let faizOraniSplited = this.faizBilgileri.faizOrani.split(".");
            this.faizBilgileri.faizOraniTL = faizOraniSplited[0];
            if(faizOraniSplited.length > 1)
                this.faizBilgileri.faizOraniKurus = faizOraniSplited[1];

        } else {
            // @ts-ignore
            this.faizBilgileri = {};
        }
    }

    setSelectedTarafList(alacakKalemiNode: HTMLUnknownElement, tarafList: TarafInterface[]) {
        let selectedTarafList: any[] = [];
        alacakKalemiNode.querySelectorAll('ref').forEach((refDomNode) => {
            let refAttributeValue = refDomNode.getAttribute('id');
            let filtered = tarafList.filter((tarafController) => tarafController.refId == refAttributeValue);
            if (filtered.length == 1) {
                let index = tarafList.indexOf(filtered[0]);
                selectedTarafList.push(index)
            }
        });
        this.selectedTarafList = selectedTarafList.join(',');
    }

    faizSureTipConverter(value: any): string {
        if (value == "1")
            return "Aylık";
        if (value == "2")
            return "Yıllık";
        return "";
    }

    static getAlacakKalemListFromDom(
        node: HTMLUnknownElement,
        args: Args,
        tarafList: Array<TarafKisi | TarafKurum>
    ): AlacakKalem[] {
        let alacakKalemleri: AlacakKalem[] = [];
        node.querySelectorAll('alacakkalemi').forEach(
            (node: any) => {
                let alacakKalemi = AlacakKalem.parse(node, args, tarafList)
                if (alacakKalemi) {
                    alacakKalemleri.push(alacakKalemi);
                }
            })
        return alacakKalemleri;
    }

    toUyapJson() {
        let {temelBilgileri, faizBilgileri, selectedTarafList} = this;
        // @ts-ignore
        delete temelBilgileri.alacakKalemKodAciklama;
        // @ts-ignore
        delete temelBilgileri.alacakKalemKod;
        return {
            selectedTarafList,
            temelBilgileri,
            faizBilgileri: {
                selectedFaizTuru: faizBilgileri.selectedFaizTuru,
                //faizOrani: faizBilgileri.faizOrani,
                faizOraniTL: faizBilgileri.faizOraniTL,
                faizOraniKurus: faizBilgileri.faizOraniKurus,
                selectedFaizSureTipi: faizBilgileri.selectedFaizSureTipi,
                selectedFaizSureTipiAciklama: faizBilgileri.selectedFaizSureTipiAciklama
            }
        }
    }
}

