import app from "@/main";
import {TaskDurum} from "../../../enum/TaskDurum";
import {DosyaBorclu} from "../../../uyap/DosyaBorclu";
import {DosyaBorclulariCevap} from "../../../uyap/DosyaBorclulari";
import {AvukatDosya} from "../../../uyap/AvukatDosya";
import {AbstractTaskHandler} from "./AbstractTaskHandler";
import {ITask} from "../GorevYoneticisiTypes";
import {TakipSorguAlanlani} from "../TaskDataInterface/TakipSorgulaTaskDataInterface";
import {DosyaBorcluSSKCevap, SigortaDurumKod} from "@/plugins/uyap-plugin/uyap/DosyaBorcluSSK";
import {IKisiTumDVO, IKurumDVO} from "@/plugins/uyap-plugin/uyap/CommonInterfaces";

export class TakipSorgulaTaskCevap {
    borclular: DosyaBorclulariCevap = [];
    dosya!: AvukatDosya;
    status: TaskDurum = TaskDurum.SIRADA;
    hataliSorguAlanlari: any[] = [];
    error: string = "";
}

export class TakipSorgulaTaskHandler extends AbstractTaskHandler {
    data: TakipSorgulaTaskCevap = new TakipSorgulaTaskCevap();

    async handle(task: ITask): Promise<ITask> {
        try {
            let dosya = await app.$uyap.DosyaBul().run(task);
            this.data.dosya = new AvukatDosya(dosya);
            let seciliBorcluSorgulamaAlanlari = task.data.sorguAlanlari.filter((item: TakipSorguAlanlani) => item.borcluyaOzel === true)
            try {
                if (this.borcluSorgulanacakMi(task.data.sorguAlanlari)) {
                    this.data.borclular = await app.$uyap.DosyaBorclulari().run({
                        dosyaId: dosya.dosyaId
                    });
                    console.log("Task data borclular", this.data.borclular);
                    await this.borcluAlanlariSorgula(seciliBorcluSorgulamaAlanlari)
                }
            } catch (e) {
                this.data.error = e;
                this.data.hataliSorguAlanlari = seciliBorcluSorgulamaAlanlari;
                this.data.status = TaskDurum.BITTI_EKSIK;
            }
            let seciliDosyaSorgulamaAlanlari = task.data.sorguAlanlari.filter((item: TakipSorguAlanlani) => item.borcluyaOzel === false)
            await this.dosyaAlanlariSorgula(seciliDosyaSorgulamaAlanlari);
            let icraproyaAktarilsinmi = task.data.sorguAlanlari.find((x: any) => x.name == "icraproaktar");
            console.log("icraproya AKTARILSINMI", icraproyaAktarilsinmi);
            if (icraproyaAktarilsinmi) {
                try{
                    await app.$http.post('/api/v1/task/takip-senkron', this.data);
                }catch (e) {
                    console.log("e",e);
                }
            }

            task.status = TaskDurum.BITTI_BASARILI;
            console.log("task.status ", task.status)
            console.log("this.data.status  ", this.data.status)
            if (this.data.status == TaskDurum.BITTI_EKSIK) {
                console.log("ifffff   this.data.status  ", this.data.status)
                task.status = TaskDurum.BITTI_EKSIK;
            }
            let hataliAlanSayac: any = {};
            this.data.hataliSorguAlanlari.forEach((alan) => {
                let alanName = alan.name;
                if (hataliAlanSayac[alanName])
                    hataliAlanSayac[alanName]++;
                else
                    hataliAlanSayac[alanName] = 1;
            });
            console.log('Hatalı alan sayaç ', hataliAlanSayac)
            if (Object.keys(hataliAlanSayac).length == task.data.sorguAlanlari.length) {
                task.status = TaskDurum.BITTI_HATALI;
            }
            console.log("task.status ", task.status)
            task.response = this;
            return task;
        } catch (e) {
            console.log("task catch", e);
            task.status = TaskDurum.BITTI_HATALI;
            this.data.error = e;
            task.description = e.message;
            throw new Error(e.message);
        }
    }

    async dosyaAlanlariSorgula(sorguAlanlari: any[]): Promise<void> {
        return new Promise(async (resolve) => {
            try {
                for (const alan of sorguAlanlari) {
                    await this.dosyaAlanSorgula(alan)
                }
                return resolve();
            } catch (e) {
                console.log('CATCH dosyaAlanlariSorgula', e);
                return resolve();
            }
        })
    }

    async borcluAlanlariSorgula(sorguAlanlari: any[]): Promise<void> {
        return new Promise(async (resolve) => {
            for (let borclu of this.data.borclular) {
                try{
                    for (const alan of sorguAlanlari) {
                        await this.borcluAlanSorgula(borclu, alan);
                    }
                }catch (e) {
                    console.log("borcluAlanSorgula catch",e);
                    if( e.message == "Borçlu hakkındaki icra takibi kesinleştirilmediği için sorgulama yapamazsınız. " ||
                        e.message == 'Bu işlemi bu dosya için gerçekleştiremezsiniz. Dosyada ALACAKLI vekili değilsiniz. ')
                    {
                        for (let borcluH of this.data.borclular) {
                            borcluH.setBorcluErrorAllFields(e.message);
                        }
                        for (const alanData of sorguAlanlari) {
                            let alan= {...alanData, message: e.message}
                            this.data.hataliSorguAlanlari.push(alan);
                        }
                        this.data.status = TaskDurum.BITTI_EKSIK;
                        console.log('Bitti döngüden çık!', borclu);
                        break;
                    }
                }
            }
            console.log("borcluAlanlariSorgula resolve");
            return resolve();
        })
    }

    async dosyaAlanSorgula(alan: any): Promise<void> {
        return new Promise(async (resolve) => {
            try {
                switch (alan.name) {
                    //Borclu olmayalnlar
                    case "taraflar":
                        await this.data.dosya.queryTaraflar();
                        break;
                    case "safahat":
                        await this.data.dosya.querySafahat();
                        break;
                    case "tahred":
                        await this.data.dosya.queryTahRed();
                        break;
                    case "evraklar":
                        await this.data.dosya.queryEvraklar();
                        break;
                    case "dosyadetay":
                        await this.data.dosya.queryDosyaBilgileri();
                        break;
                    case "dosyahesap":
                        await this.data.dosya.queryDosyaHesapBilgileri();
                        break;
                    case "barkod":
                        await this.data.dosya.queryBarkod();
                        break;
                    case "mtstebligat":
                        await this.data.dosya.queryMtsTebligat();
                        break;
                }
                return resolve();
            } catch (e) {
                alan.message = e.message;
                this.data.hataliSorguAlanlari.push(alan);
                this.data.status = TaskDurum.BITTI_EKSIK;
                console.log("dosyaAlanSorgula CATCH", e);
                return resolve();
            }
            //TODO "Bu işlemi bu dosya için gerçekleştiremezsiniz. Dosyada ALACAKLI vekili değilsiniz. "
        })
    }

    async borcluAlanSorgula(borclu: DosyaBorclu, alan: any): Promise<void> {
        return new Promise(async (resolve,reject) => {
            try {
                let dosyaId = this.data.dosya.dosya.dosyaId;
                switch (alan.name) {
                    case "sgkssk":
                        await borclu.queryBorcluSsk(dosyaId)
                        break;
                    case "egm":
                        await borclu.queryEgm(dosyaId)
                        break;
                    case "egmmahrumiyet":
                        await borclu.queryEgmMahrumiyet(dosyaId)
                        break;
                    case "sskaktif":
                        await this.borcluSskAktifSorgula(borclu);
                        break;
                    case "mernis":
                        await borclu.queryBorcluMernis(dosyaId);
                        break;
                    case "mersis":
                        await borclu.queryBorcluMersis(dosyaId);
                        break;
                    case "sgkkamu":
                        await borclu.queryBorcluSgkKamu(dosyaId);
                        break;
                    case "gib":
                        await borclu.queryBorcluGib(dosyaId);
                        break;
                    case "sgkbagkur":
                        await borclu.queryBorcluSgkBagkur(dosyaId);
                        break;
                    case "dib":
                        await borclu.queryBorcluDib(dosyaId);
                        break;
                    case "sgkisyeri":
                        await borclu.queryBorcluSgkIsyeri(dosyaId);
                        break;
                    case "takbis":
                        await borclu.queryBorcluTakbis(dosyaId);
                        break;
                    case "sgkhaciz":
                        await borclu.queryBorcluSgkHaciz(dosyaId);
                        break;
                    case "iski":
                        await borclu.queryBorcluIski(dosyaId);
                        break;
                    case "icradosyakaydi":
                        await borclu.queryBorcluIcraDosyaKaydi(dosyaId);
                        break;
                    case "gsm":
                        await borclu.queryBorcluGsm(dosyaId);
                        break;
                    case "postaceki":
                        await borclu.queryBorcluPostaCeki(dosyaId);
                        break;
                    case "bankahesap":
                        await borclu.queryBorcluBankaBilgileri(dosyaId);
                        break;
                }
                return resolve();
            } catch (e) {
                console.log("borcluAlanlariSorgula CATCH", e);

                if (e.message == "Borçlu hakkındaki icra takibi kesinleştirilmediği için sorgulama yapamazsınız. " ||
                    e.message == 'Bu işlemi bu dosya için gerçekleştiremezsiniz. Dosyada ALACAKLI vekili değilsiniz. ') {
                    return reject(e);
                }
                if (borclu.isKisi()) {
                    let borcluData = borclu.borcluBilgileri as IKisiTumDVO;
                    alan.message = borcluData.adi + " " + borcluData.soyadi + ": " + e.message;
                } else {
                    let borcluData = borclu.borcluBilgileri as IKurumDVO;
                    alan.message = borcluData.kurumAdi + ": " + e.message;
                }
                this.data.hataliSorguAlanlari.push(alan);
                this.data.status = TaskDurum.BITTI_EKSIK;
                return resolve();
            }
        })
    }

    async borcluSskAktifSorgula(borclu: DosyaBorclu): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                await borclu.queryBorcluSsk(this.data.dosya.dosya.dosyaId)
                let ssk=borclu.ssk as DosyaBorcluSSKCevap;
                if (ssk.sorguSonucDVO?.sskBilgiDetay.sgkIsyeriDVO.sigortaliBilgi.durumKod == SigortaDurumKod.AKTIF)
                    borclu.sskAktif = true;
                else
                    borclu.sskAktif = false
                return resolve();
            } catch (e) {
                return reject(e);
            }
        })

    }

    borcluSorgulanacakMi(sorguAlanlari: any[]): boolean {
        let flag = false;
        sorguAlanlari.forEach(alan => {
            if (alan.borcluyaOzel == true)
                flag = true;
        })
        return flag;
    }
}