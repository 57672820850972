

import {Component, Vue, Watch} from "vue-property-decorator";
import {BildirimEntity} from "@/entity/BildirimEntity";

@Component({
  components: {}
})
export default class BildirimInfo extends Vue {
  bildirimler: Array<BildirimEntity> = [];
  isNotificationsGetOnce = false;

  get okunmamisBildirim() {
    if (this.bildirimler.length > 0)
      return this.bildirimler.filter((item) => (!item.okundu_mu)).length;
    else
      return 0;
  }

  get okunmusBildirim() {
    if (this.bildirimler.length > 0)
      return this.bildirimler.filter((item) => (item.okundu_mu)).length;
    else
      return 0;
  }

  @Watch('bildirimler')
  onBildirimlerChange(yeniBildirimler: Array<BildirimEntity>, eskiBildirimler: Array<BildirimEntity>) {
    if (this.isNotificationsGetOnce) {
      let fark: Array<BildirimEntity> = yeniBildirimler.filter(x => {
        return !eskiBildirimler.some((v) => v.id == x.id)
      });
      fark.reverse().forEach((v: BildirimEntity) => {
        this.$toast.info(v.aciklama);
      })
      let userGuncelle = false;
      fark.forEach((bildirim) => bildirim.baslik == 'İzinler Güncellendi' ? (userGuncelle = true) : null);
      if (userGuncelle)
        this.$http.get("/api/v1/ben").then((response) => {
          this.$store.commit('setUser', response);
        })
    }
  }

  mounted() {
    this.bildirimler = []
    this.$http.get('/api/v1/personel/' + this.$store.state.user.id + '/bildirim').then((response) => {
      this.bildirimler = response.data.reverse();
    });
    this.loopLoad();
  }

  loopLoad() {
    setTimeout(() => {
      this.isNotificationsGetOnce = true;
      this.$http.get('/api/v1/personel/' + this.$store.state.user.id + '/bildirim').then((response) => {
        this.bildirimler = response.data.reverse();
        this.loopLoad();
      })
    }, 20000);
  }

  singleLoad() {
    this.$http.get('/api/v1/personel/' + this.$store.state.user.id + '/bildirim').then((response) => {
      this.bildirimler = response.data.reverse();
    });
  }

  tumuOkundu() {
    this.$http.post('/api/v1/personel/' + this.$store.state.user.id + '/bildirim-okuma').then(()=>(this.singleLoad()));
  }

  okunanlariTemizle() {
    this.$http.post('/api/v1/personel/' + this.$store.state.user.id + '/bildirim-okundu-sil').then(()=>(this.singleLoad()));
  }

  bildirimiOkuOkuma(bildirim: BildirimEntity) {
    bildirim.okundu_mu = !bildirim.okundu_mu;
    this.$http.put('/api/v1/bildirim/' + bildirim.id, bildirim).then(() => {
      this.singleLoad();
    });
  }
}
