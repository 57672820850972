import _Vue from "vue";
import Crypto from "crypto";
import app from "@/main";
import {Evrak} from "./store/modules/TaskDataInterface/EvrakGonderTaskDataInterface";
import {ITask} from "@/plugins/uyap-plugin/store/modules/GorevYoneticisiTypes";
import JSZip from "jszip";
import FileSaver from "file-saver";
import {TakipTalebiIndirCevap} from "@/plugins/uyap-plugin/uyap/TakipTalebiIndir";

export class UyapHelper {
    base64toBlob(base64Data: any, contentType: any): Blob {
        contentType = contentType || '';
        const sliceSize = 1024;
        const byteCharacters = window.atob(base64Data);
        const bytesLength = byteCharacters.length;
        const slicesCount = Math.ceil(bytesLength / sliceSize);
        const byteArrays = new Array(slicesCount);

        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            const begin = sliceIndex * sliceSize;
            const end = Math.min(begin + sliceSize, bytesLength);

            const bytes = new Array(end - begin);
            for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return new Blob(byteArrays, {type: contentType});
    }

    base64DataCleaner(data: Evrak): Evrak {
        let splitedB64: any = (data.base64 as string).split(";base64,");
        if (splitedB64.length > 1) {
            data.base64 = splitedB64[1];
            data.type = splitedB64[0].split("data:")[1]
        }
        return data;
    }

    formatDate(tarih: any){
        let day = tarih.date.day < 10 ? ("0" + tarih.date.day) : tarih.date.day;
        let month = tarih.date.month < 10 ? ("0" + tarih.date.month) : tarih.date.month;
        let year = tarih.date.year;
        let hour = tarih.time.hour < 10 ? ("0" + tarih.time.hour) : tarih.time.hour;
        let minute = tarih.time.minute < 10 ? ("0" + tarih.time.minute) : tarih.time.minute;
        let second = tarih.time.second < 10 ? ("0" + tarih.time.second) : tarih.time.second;
        return year + "-" + month + "-" + day + "T" + hour + ":" + minute + ":" + second;
    }

    tarihLocaleStr(dosyaAcilisTarihi: any) {
        let day = dosyaAcilisTarihi.date.day < 10 ? ("0" + dosyaAcilisTarihi.date.day) : dosyaAcilisTarihi.date.day;
        let month = dosyaAcilisTarihi.date.month < 10 ? ("0" + dosyaAcilisTarihi.date.month) : dosyaAcilisTarihi.date.month;
        let hour = dosyaAcilisTarihi.time.hour < 10 ? ("0" + dosyaAcilisTarihi.time.hour) : dosyaAcilisTarihi.time.hour;
        let minute = dosyaAcilisTarihi.time.minute < 10 ? ("0" + dosyaAcilisTarihi.time.minute) : dosyaAcilisTarihi.time.minute;
        let second = dosyaAcilisTarihi.time.second < 10 ? ("0" + dosyaAcilisTarihi.time.second) : dosyaAcilisTarihi.time.second;
        let tarih = day + "." + month + "." + dosyaAcilisTarihi.date.year + " " +
            hour + ":" + minute + ":" + second;
        return tarih;
    }

    hexToBase64(hex: string): string {
        const binary = hex.match(/.{1,2}/g)!.map(byte => parseInt(byte, 16));
        const bytes = new Uint8Array(binary);
        // @ts-ignore
        const base64 = btoa(String.fromCharCode.apply(null, bytes));
        return base64;
    }

    async topluTakipTalebiIndir(uyapDosyalar: Array<{dosyaId: string}>) {
        return new Promise(async (resolve) => {
            let zip = new JSZip();
            for (const uyapDosya of uyapDosyalar) {
                try {
                    let blobData: TakipTalebiIndirCevap = await app.$uyap.TakipTalebiIndir(uyapDosya.dosyaId).run({
                        download: true,
                        filename: "takip_talebi.udf"
                    });
                    zip.file("Takip Talebi_"+uyapDosya.dosyaId+".udf", blobData.file);
                } catch {
                    continue;
                }
            }
            zip.generateAsync({type: "blob"})
                .then(function (contents) {
                    FileSaver.saveAs(contents, "Takip Talepleri");
                    // @ts-ignore
                    resolve();
                });
        })
    }

    trToEn(text: string): string {
        return text.toLowerCase()
            .replace(/Ç/g, 'c')
            .replace(/ç/g, 'c')
            .replace(/ö/g, 'o')
            .replace(/Ö/g, 'o')
            .replace(/Ü/g, 'u')
            .replace(/ü/g, 'u')
            .replace(/Ğ/g, 'g')
            .replace(/ğ/g, 'g')
            .replace(/Ş/g, 's')
            .replace(/ş/g, 's')
            .replace(/İ/g, 'i')
            .replace(/ı/g, 'i')
    }

    seoUrl(text: string): string {
        text = text.replace(/\ /g, '-')
            .replace(/\(/g, '-')
            .replace(/\)/g, '-')
            .replace(/\//g, '-')
            .replace(/\\/g, '-');
        return this.trToEn(text);
    }

    turkishToUpper(str: string) {
        let letters: any = {
            "a": "A",
            "b": "B",
            "c": "C",
            "d": "D",
            "e": "E",
            "f": "F",
            "g": "G",
            "ğ": "Ğ",
            "h": "H",
            "ı": "I",
            "i": "İ",
            "j": "J",
            "k": "K",
            "l": "L",
            "m": "M",
            "n": "N",
            "o": "O",
            "ö": "Ö",
            "p": "P",
            "r": "R",
            "s": "S",
            "ş": "Ş",
            "t": "T",
            "u": "U",
            "ü": "Ü",
            "v": "V",
            "y": "Y",
            "z": "Z",
            "A": "A",
            "B": "B",
            "C": "C",
            "D": "D",
            "E": "E",
            "F": "F",
            "G": "G",
            "Ğ": "Ğ",
            "H": "H",
            "I": "I",
            "İ": "İ",
            "J": "J",
            "K": "K",
            "L": "L",
            "M": "M",
            "N": "N",
            "O": "O",
            "Ö": "Ö",
            "P": "P",
            "R": "R",
            "S": "S",
            "Ş": "Ş",
            "T": "T",
            "U": "U",
            "Ü": "Ü",
            "V": "V",
            "Y": "Y",
            "Z": "Z"
        }
        let out = "";
        for (let i = 0; i < str.length; i++) {
            if (typeof letters[str[i]] != "undefined")
                out += letters[str[i]];
            else
                out += str[i];
        }
        return out;
    }

    turkishToLower(str: string) {
        let letters: any = {"İ": "i", "I": "ı", "Ş": "ş", "Ğ": "ğ", "Ü": "ü", "Ö": "ö", "Ç": "ç"};
        str = str.replace(/(([İIŞĞÜÇÖ]))+/g, (letter: any) => {
            return letters[letter];
        })
        return str.toLowerCase();
    }

    getDateNow(seperator: string, dateDiff: any, donemYil = false) {
        let today: Date | string = new Date();
        let dd: string | number = today.getDate();
        let mm: string | number = today.getMonth(); //January is 0!
        let yyyy = today.getFullYear();
        if (dateDiff) {
            if (dateDiff.indexOf("month") >= 0) {
                if (dateDiff.indexOf("-") >= 0) {
                    today.setMonth(mm - dateDiff.split('-')[1].trim());
                } else {
                    today.setMonth(mm + parseInt(dateDiff.split('+')[1].trim()));
                }
            }
            if (dateDiff.indexOf("day") >= 0) {
                if (dateDiff.indexOf("-") >= 0) {
                    today.setDate(dd - dateDiff.split('-')[1].trim());
                } else {
                    today.setDate(dd + parseInt(dateDiff.split('+')[1].trim()));
                }
            }
            if (dateDiff.indexOf("year") >= 0) {
                if (dateDiff.indexOf("-") >= 0) {
                    today.setFullYear(yyyy - dateDiff.split('-')[1].trim());
                } else {
                    today.setFullYear(yyyy + parseInt(dateDiff.split('+')[1].trim()));
                }
            }
        }
        dd = today.getDate();
        mm = today.getMonth() + 1;
        yyyy = today.getFullYear();

        if (!seperator)
            seperator = '/';
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }
        today = dd + seperator + mm + seperator + yyyy;
        if (donemYil == true) {
            today = yyyy + seperator + mm;
        }
        return today;
    }

    hash(obj: any) {
        return Crypto.createHash('sha1').update(JSON.stringify(obj)).digest().toString('hex');
    }

    async getBlobFromLink(URL: string): Promise<Blob> {
        return new Promise((resolve, reject) => {
            app.$http({
                url: URL,
                method: 'GET',
                responseType: 'blob'
            }).then((response: any) => {
                console.log("response Blob",response);
                return resolve(response);
            }).catch((error) => {
                app.$toast.error(error.message)
                return reject(error);
            });
        })
    }

    changeUyapDateFormat(date: string): string {
        let tarih = new Date(date);
        let tarihObj = {
            gun: tarih.getDate(),
            ay: (tarih.getMonth() + 1),
            yil: tarih.getFullYear()
        }
        return `${tarihObj.gun}.${tarihObj.ay}.${tarihObj.yil}`;
    }

    tcKimlikNoValidate(tcKimlikNo: string): boolean {
        if(tcKimlikNo == "" || tcKimlikNo == null)
            return false;
        let value10 = Number(tcKimlikNo.toString().substring(10, 11));
        let value9 = Number(tcKimlikNo.toString().substring(9, 10));
        let odd = Number(tcKimlikNo.toString().substring(0, 1)) + Number(tcKimlikNo.toString().substring(2, 3)) +
            Number(tcKimlikNo.toString().substring(4, 5)) + Number(tcKimlikNo.toString().substring(6, 7)) +
            Number(tcKimlikNo.toString().substring(8, 9));
        let even = Number(tcKimlikNo.toString().substring(1, 2)) + Number(tcKimlikNo.toString().substring(3, 4)) +
            Number(tcKimlikNo.toString().substring(5, 6)) + Number(tcKimlikNo.toString().substring(7, 8));
        let digit10 = (odd * 7 - even) % 10;
        if (digit10 < 0) digit10 += 10;
        let total = (odd + even + value9) % 10;

        return !(digit10 != value9 || total != value10)
    }

    onlyDigit(text: string) {
        return text.replace(/[^0-9]/g, "");
    }

    setCookie(name: string, value: any, days: number) {
        let expires = "";
        if (days) {
            let date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }

    async takipSorgulaWordDownload(task: ITask) {
        let zip = new JSZip();
        for (const cTask of (task.childTasks as Array<ITask>)) {
            let path = (cTask.birim_adi + " - " + cTask.dosya_esas_no).replace('/', '_');
            let sorgulananAlanlar = cTask.data.sorguAlanlari.map((item: any) => item.name);
            let hataliAlanlar = cTask.response.data.hataliSorguAlanlari.map((item: any) => item.name);
            let header = "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
                "xmlns:w='urn:schemas-microsoft-com:office:word' " +
                "xmlns='http://www.w3.org/TR/REC-html40'>" +
                "<head><meta charset='utf-8'><title>Save File</title></head><body>";
            let footer = "</body></html>";
            // dosya alanaları
            if (hataliAlanlar.indexOf('dosyahesap') == -1 && sorgulananAlanlar.indexOf('dosyahesap') > 0) {
                let sourceHTML = header + cTask.response.data.dosya.dosyaHesapBilgileri.html + footer;
                const file = new Blob([sourceHTML], {type: 'application/vnd.ms-word;charset=utf-8'});
                zip.file(path + "/Dosya Hesap Bilgileri.doc", file);
            }
            if (hataliAlanlar.indexOf('tahred') == -1 && sorgulananAlanlar.indexOf('tahred') > 0) {
                let sourceHTML = header + cTask.response.data.dosya.tahsilatReddiyat.html + footer;
                const file = new Blob([sourceHTML], {type: 'application/vnd.ms-word;charset=utf-8'});
                zip.file(path + "/Tahsilat Reddiyat.doc", file);
            }
            // borçlu alanları
            for (let borclu of cTask.response.data.borclular.items) {
                let borcluPath = "/" + (borclu.kurumAdi ? borclu.kurumAdi : (borclu.adi + " " + borclu.soyadi));
                if (hataliAlanlar.indexOf('postaceki') == -1 && sorgulananAlanlar.indexOf('postaceki') > 0) {
                    let sourceHTML = header + borclu.postaCeki.html + footer;
                    const file = new Blob([sourceHTML], {type: 'application/vnd.ms-word;charset=utf-8'});
                    zip.file(path + borcluPath + "/Posta Çeki.doc", file);
                }
                if (hataliAlanlar.indexOf('bankahesap') == -1 && sorgulananAlanlar.indexOf('bankahesap') > 0) {
                    let sourceHTML = header + borclu.bankaBilgileri.html + footer;
                    const file = new Blob([sourceHTML], {type: 'application/vnd.ms-word;charset=utf-8'});
                    zip.file(path + borcluPath + "/Banka Hesap Bilgileri.doc", file);
                }
                if (hataliAlanlar.indexOf('takbis') == -1 && sorgulananAlanlar.indexOf('takbis') > 0) {
                    let sourceHTML = header + borclu.takbis.html + footer;
                    const file = new Blob([sourceHTML], {type: 'application/vnd.ms-word;charset=utf-8'});
                    zip.file(path + borcluPath + "/Takbis.doc", file);
                }
                if (hataliAlanlar.indexOf('dib') == -1 && sorgulananAlanlar.indexOf('dib') > 0) {
                    let sourceHTML = header + borclu.dib.html + footer;
                    const file = new Blob([sourceHTML], {type: 'application/vnd.ms-word;charset=utf-8'});
                    zip.file(path + borcluPath + "/Dış İşleri Bilgileri.doc", file);
                }
                if (hataliAlanlar.indexOf('gib') == -1 && sorgulananAlanlar.indexOf('gib') > 0) {
                    let sourceHTML = header + borclu.gib.html + footer;
                    const file = new Blob([sourceHTML], {type: 'application/vnd.ms-word;charset=utf-8'});
                    zip.file(path + borcluPath + "/GİB.doc", file);
                }
                if (hataliAlanlar.indexOf('gsm') == -1 && sorgulananAlanlar.indexOf('gsm') > 0) {
                    let sourceHTML = header + borclu.gsm.html + footer;
                    const file = new Blob([sourceHTML], {type: 'application/vnd.ms-word;charset=utf-8'});
                    zip.file(path + borcluPath + "/GSM.doc", file);
                }
                if (hataliAlanlar.indexOf('iski') == -1 && sorgulananAlanlar.indexOf('iski') > 0) {
                    let sourceHTML = header + borclu.iski.html + footer;
                    const file = new Blob([sourceHTML], {type: 'application/vnd.ms-word;charset=utf-8'});
                    zip.file(path + borcluPath + "/İSKİ.doc", file);
                }
                if (hataliAlanlar.indexOf('mernis') == -1 && sorgulananAlanlar.indexOf('mernis') > 0) {
                    let sourceHTML = header + borclu.mersis.html + footer;
                    const file = new Blob([sourceHTML], {type: 'application/vnd.ms-word;charset=utf-8'});
                    zip.file(path + borcluPath + "/Mernis.doc", file);
                }
                if (hataliAlanlar.indexOf('sgkhaciz') == -1 && sorgulananAlanlar.indexOf('sgkhaciz') > 0) {
                    let sourceHTML = header + borclu.sgkhaciz.html + footer;
                    const file = new Blob([sourceHTML], {type: 'application/vnd.ms-word;charset=utf-8'});
                    zip.file(path + borcluPath + "/SGK Haciz.doc", file);
                }
                if (hataliAlanlar.indexOf('icradosyakaydi') == -1 && sorgulananAlanlar.indexOf('icradosyakaydi') > 0) {
                    let sourceHTML = header + borclu.icraDosyaKaydi.html + footer;
                    const file = new Blob([sourceHTML], {type: 'application/vnd.ms-word;charset=utf-8'});
                    zip.file(path + borcluPath + "/İcra Dosya Kaydı.doc", file);
                }
                if (hataliAlanlar.indexOf('egm') == -1 && sorgulananAlanlar.indexOf('egm') > 0) {
                    let sourceHTML = header + borclu.egm.html + footer;
                    const file = new Blob([sourceHTML], {type: 'application/vnd.ms-word;charset=utf-8'});
                    zip.file(path + borcluPath + "/EGM.doc", file);
                }
            }
        }
        let contents = await zip.generateAsync({type: "blob"})
        FileSaver.saveAs(contents, 'Word Sorgu Sonuçları ' + new Date(task.updated_at).toISOString().substr(0, 10));
    }
}

export default function HelperPlugin(Vue: typeof _Vue): void {
    Vue.prototype.$uyapHelper = new UyapHelper();
}